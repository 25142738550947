
function Header() {
    return (
        <div id="Header" className="header">
            <img
                alt="Logo"
                src="/img/logo.jpg"
            />
            <p>
                This page is only to meet Google Play Store and App Store requirements.
            </p>
        </div>
    );
}

export default Header;