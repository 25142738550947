import Footer from "../components/Footer";
import Header from "../components/Header";
import Main from "../components/Main";

function Home() {
  return (
    <>
      <Header />
      <div className='base'>
        <Main />
        <Footer />
      </div>
    </>
  );
}

export default Home;