function Footer() {
  return (
    <div id={"Footer"} className="footer">
      <p>All rights reserved</p>
      <p>Atherio App</p>
      <p>@FCV 2024</p>
    </div>
  );
}

export default Footer;