function Main() {
    return (
        <div id="Main" className="main">
            <a href="https://apps.apple.com/tr/app/atherio-generate-schedules/id1564637780" target="_blank" rel="noreferrer">
                <img style={{ padding: 10 }} alt="AppStore"
                    src="/img/appstore.png" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=fcv.atherio" target="_blank" rel="noreferrer">
                <img style={{ padding: 10 }} alt="GooglePlayStore"
                    src="/img/playstore.png" />
            </a>
            <h1>How to delete my account?</h1>
            <p>
                First option: Log in to your account in Atherio App (mobile). Go to your profile and click on the settings button (gear).
                Click on "Edit information" and write your password again. Then click on "Delete account" button.

                Second option: Send an email to "felipecruzv10@gmail.com" asking for the deletion of your account.
                The email must be sent with the email related to the Atherio account you want to delete.
            </p>
            <h1>How to delete data without deleting my account?</h1>
            <p>
                Take into account that all data related to schedules can be deleted by yourself inside your profile.

                First option: Log in to your account in Atherio App (mobile). Go to your profile and click on the "Help" button.
                Click on "Contact us" and write your query. Then click on "Send" button.

                Second option: Send an email to "felipecruzv10@gmail.com" asking for your query.
                The email must be sent with the email related to the Atherio account you want to delete information of.
            </p>
            <h1>Terms and conditions</h1>
            <p>
                This document establishes the conditions for the use of the Atherio application
                (hereinafter "app"), which was developed independently by its creator (author), with the support
                of the Colombian company FCV Inversiones (this company is not responsible for any function or
                information, it only supported the publication of the app financially).

                The app is designed so that university students can obtain all their possible schedules in a
                given semester. However, it can be used to create schedules with activities of any kind.
                The app is not responsible for any of the consequences arising from the creation of schedules.

                To use the app it will be mandatory to accept the stated information in this document.

                The app reserves the right to modify, without prior notice and without the consent of the users,
                any type of information that is handled in it (except for passwords and emails of the
                users, but including this document). This change can be made in the form of an update,
                or just changing the database that Atherio uses.

                The app will be available for Android and IOS mobile devices, through the services
                offered by the Google Play Store (Android) and the App Store (Apple), in countries whose
                regulations allow such an application. The app is available in Spanish and English languages,
                depending on the default language on the device where it was installed.

                The app offers the Firebase Realtime Database service to save the schedules that the user
                generated, as long as the user has an account created in the app. If any schedule
                is deleted, the app is not responsible for the consequences derived from the event.

                The app accounts are controlled by the Firebase Authentication service. Each user
                is identified through the email (which must be validated), so that the username is only a
                personalization element, that is, several users can have the
                same username but they will be differentiated by their email.

                The app sends validation emails, password recovery emails, and email change emails;
                through the service offered by Firebase Authentication.

                The app reserves the right to disable accounts, if the creator considers so. In which case,
                you can use the "Guest" option, which allows you to generate schedules, but not save them.

                Neither the app, nor its creator, nor the FCV Inversiones company have access to user´s password,
                whose control is done by the Firebase Authentication service. However, the app
                has access to other information; such as email, saved schedules, username, etc.

                Users have total freedom to edit their data whenever they want, from the app. If you
                want to delete your account, you must send a message through the "Help" activity within
                the app, requesting your account to be deleted (deleted accounts cannot be recovered).

                The messages sent through the "Help" activity are only accessible for support members of
                the app and are deleted once they are solved. These messages are sent along with the user's email
                to be able to communicate with the user (via email) if necessary.

                The app generates profit by presenting advertisements, which are presented
                using Appodeal´s service. The app is not responsible for the content featured in ads.

                The app uses an analytics service offered by Google (Google Analytics), for the sole purpose
                of improving the functionality, fixing bugs, and allowing a more enjoyable use of the app.

                The app is not intended to be profitable for the creator or the associated company.
                The income is destined to the maintenance of the app, the payment of the services used,
                keeping the app available in the Google Play Store and App Store, and payments for support staff
                members. However, remaining money after the aforementioned will belong to FCV Inversiones.

                The logo, design and name of the app belong to its creator. No reproduction of the elements of
                the app is allowed without the express authorization of the creator (which may be requested
                via message in the "Help" activity).

                The app will use device storage to obtain the following information each
                once the app starts (screen with the Atherio logo):
                - Background´s screen color
                - Know if the user is new
                - Email and password for auto-login
                - Language
                - Know if the user already did the tutorial

                Services used by the app:
                - Firebase Authentication
                - Firebase Realtime Database
                - Google Analytics
                - Appodeal (Ads)
                Atherio 2021
            </p>
        </div>
    );
}

export default Main;